import React from 'react';
import { Route, Routes, BrowserRouter } from 'react-router-dom';

import paths from './paths';
import SetupAccountPage from '../setupAccount';

const RouterComponent = () => {

  return ( 
    <BrowserRouter>
      <Routes>
        <Route exact path={paths.SETUPACCOUNT} element={<SetupAccountPage />} />
      </Routes>
    </BrowserRouter>
  );
};

export default RouterComponent;