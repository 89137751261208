import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { verifyPasswordResetCode, confirmPasswordReset } from 'firebase/auth';
import { auth } from '../../firebase';

const SetupAccountPage = () => {

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  // Extract the query parameters
  const apiKey = queryParams.get('apiKey');
  const mode = queryParams.get('mode');
  const oobcode = queryParams.get('oobCode');

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  console.log("oobcode",oobcode)
  useEffect(() => {
    // Verify the oobcode and mode when the component mounts
    verifyOobCodeAndMode();
  }, []);

  const verifyOobCodeAndMode = async () => {
    try {
      // Verify the oobcode and mode
      await verifyPasswordResetCode(auth, oobcode);
      
      // If verification succeeds, you can set success message or handle accordingly
      setSuccessMessage('Verification succeeded. You can now set your password.');

    } catch (error) {
      console.log(error)
      // Handle verification failure, e.g., invalid oobcode or expired link
      setErrorMessage('Error verifying oobcode.', error.message);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      setErrorMessage('Passwords do not match.');
      return;
    }

    try {
      // Use Firebase's password reset functionality to set the password
      await confirmPasswordReset(auth, oobcode, password);

      setSuccessMessage('Password set successfully.');
      setErrorMessage('');
    } catch (error) {
      setErrorMessage('Error setting password.');
      setSuccessMessage('');
    }
  };

  return (
    <div>
      <h2>Set Up Your Account</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>New Password:</label>
          <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
        </div>
        <div>
          <label>Confirm Password:</label>
          <input type="password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
        </div>
        {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
        {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}
        <button type="submit">Set Password</button>
      </form>
    </div>
  );
};

export default SetupAccountPage;